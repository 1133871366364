<template>
  <text-input-field-with-validation
    :validation-rules="'required|email'"
    v-model="innerValue"
    name="Email"
    label="Courriel"
    label-for="email"
    type="email"
    placeholder="user@domain.com"
    @input="(input)=>$emit('input',input)"
  />
</template>

<script>
import TextInputFieldWithValidation from "@/shared/components/TextInputFieldWithValidation";

export default {
  name: 'email-input-field',
  components: {
    TextInputFieldWithValidation,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
