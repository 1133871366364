<template>
  <b-sidebar
    id="add-new-agency-sidebar"
    :visible="isAddNewAgencySidebarActive"
    backdrop
    bg-variant="white"
    no-header
    right
    shadow
    sidebar-class="sidebar-lg"
    @change="(val) => $emit('update:is-add-new-agency-sidebar-active', val)"
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Ajouter nouvelle agence</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
      </div>

      <!-- body -->
      <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
          <!-- Agency information -->
          <h5 class='mb-1'>Agence</h5>
          <required-text-input-field
            v-model="agencyFormData.agencyName"
            name="Agency Name"
            label="Nom de l'agence"
            label-for="agency-name"
            placeholder="Club Voyage ABC"
            autofocus
          />
          <email-input-field v-model="agencyFormData.email"/>
          <phone-number-input-field v-model="agencyFormData.phoneNumber"/>
          <required-text-input-field
            v-model="agencyFormData.branchNumber"
            name="Branch Number"
            label="Numéro TDC"
            label-for="branch-number"
            placeholder="123456"
          />

          <!-- Agency main admin information -->
          <h5 class='mt-2 mb-1'>Administrateur principal</h5>
          <required-text-input-field
            name="Admin First Name"
            v-model="agencyFormData.mainAgencyAdminFirstName"
            label="Prénom"
            label-for="main-agency-admin-first-name"
            placeholder="John"
          />
          <required-text-input-field
            name="Admin Last Name"
            v-model="agencyFormData.mainAgencyAdminLastName"
            label="Nom"
            label-for="main-agency-admin-last-name"
            placeholder="Snow"
          />
          <email-input-field v-model="agencyFormData.mainAgencyAdminEmail"/>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-2"
              type="submit"
              variant="primary"
            >
              <div v-if="isAgencyFormSubmittable">Ajouter</div>
              <div v-if="!isAgencyFormSubmittable">
              <b-spinner small type="grow"></b-spinner>
                Loading...
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {BButton, BForm, BSidebar, BSpinner} from 'bootstrap-vue';
import {ValidationObserver} from 'vee-validate';
import {reactive, ref} from '@vue/composition-api';
import {alphaNum, email, required} from '@validations';
import Ripple from 'vue-ripple-directive';
import countries from '../../../tests/mocks/countries';
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import PhoneNumberInputField from '@/shared/components/PhoneNumberInputField';
import RequiredTextInputField from '@/shared/components/RequiredTextInputField';
import EmailInputField from '@/shared/components/EmailInputField';
import {useValidatorsWithAutoFormDataCleanUp} from "@/shared/composables/use-validators";
import {useApplicationContext} from "@/shared/composables/use-application-context";

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    PhoneNumberInputField,
    RequiredTextInputField,
    EmailInputField,
    BSpinner,

    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewAgencySidebarActive',
    event: 'update:is-add-new-agency-sidebar-active',
  },
  props: {
    isAddNewAgencySidebarActive: {
      type: Boolean,
      required: true,
    }
  },
  setup: function (props, {emit}) {
    const {logger, httpClient, store} = useApplicationContext();
    let isAgencyFormSubmittable = ref(true);

    const agencyFormData = reactive({
      agencyName: '',
      email: '',
      branchNumber: '',
      phoneNumber: '',
      mainAgencyAdminFirstName: '',
      mainAgencyAdminLastName: '',
      mainAgencyAdminEmail: '',
    });

    const onSubmit = async () => {
      try {
        isAgencyFormSubmittable.value = false;
        const organizationId = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`].organization_id;
        const body = {
          name: agencyFormData.agencyName,
          email: agencyFormData.email,
          phone_number: agencyFormData.phoneNumber,
          administrator: {
            first_name: agencyFormData.mainAgencyAdminFirstName,
            last_name: agencyFormData.mainAgencyAdminLastName,
            email: agencyFormData.mainAgencyAdminEmail
          }
        };

        if (agencyFormData.branchNumber) {
          body.additional_info = [{
            name: "branch_number",
            value: agencyFormData.branchNumber
          }];
        }

        logger.info(`Organization (${organizationId}) adding agency (${agencyFormData.agencyName}) to the platform`);
        await httpClient.post(`/organizations/${organizationId}/members`, body);

        emit('refetch-data');
        emit('update:is-add-new-agency-sidebar-active', false);
        isAgencyFormSubmittable.value = true;
      } catch (error) {
        logger.error(`Could not add agency (${agencyFormData.agencyName}): ${error}`);
      }
    };

    const {refFormObserver, resetForm} = useValidatorsWithAutoFormDataCleanUp(agencyFormData);

    return {
      required,
      alphaNum,
      email,
      countries,

      agencyFormData,
      onSubmit,

      refFormObserver,
      resetForm,
      isAgencyFormSubmittable
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
