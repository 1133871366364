<template>
  <text-input-field-with-validation
    :validation-rules="{ regex:/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, required:true }"
    v-model="innerValue"
    name="Phone"
    label="Téléphone principal"
    label-for="phone"
    type="tel"
    v-mask="'(###) ###-####'"
    placeholder="222333444"
    @input="(input)=>$emit('input',input)"
  />
</template>

<script>
import TextInputFieldWithValidation from "@/shared/components/TextInputFieldWithValidation";

export default {
  name: 'phone-number-input-field',
  components: {
    TextInputFieldWithValidation,
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
