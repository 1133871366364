<template>
  <div>
    <agency-add-new :is-add-new-agency-sidebar-active.sync="isAddNewAgencySidebarActive" @refetch-data="fetchAgencies"/>
    <b-card>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input v-model="searchTerm" class="d-inline-block" placeholder="Recherche" type="text"/>
            <b-button class="ml-1" variant="primary" @click="isAddNewAgencySidebarActive = true">
              <span class="text-nowrap">Ajouter une agence</span>
            </b-button>
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{enabled: true, perPage:pageLength}"
        :rows="filteredAgencies"
        :sort-options="{
          enabled: true,
        }"
        :search-options="{enabled: false}"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
        styleClass="vgt-table"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">


            <b-badge variant='light-secondary' pill>
              <b-avatar
                :src="props.row.avatar"
                :variant="avatarColors(flattenUUIDtoBase(props.row.id))"
                icon="house-door-fill"
                size="sm"
                class='mr-1'
              />
              <span class="text-nowrap">{{ props.row.name }}</span>
            </b-badge>
          </span>

          <div v-else-if="props.column.field === 'phone_number'">
            <span>{{ props.row.phone_number | VMask('(###) ###-####') }}</span>
          </div>

          <div v-else-if="props.column.field === 'email'">
            <b-badge pill  variant='light-primary'>{{ props.row.email}}</b-badge>
          </div>
          <!-- Column: branch_number -->
          <div v-else-if="props.column.field === 'branch_number'">
            <span v-if="props.row.additional_information">
              {{props.row.additional_information.branch_number}}
            </span>
            <span v-else></span>
          </div>
          <!-- Column: Action -->
          <div v-else-if="props.column.field === 'action'" >
            <b-button
              :to="{ name: $routeNameOf('AGENCY_EDIT'), params: { agency_id: props.row.id } }"
              class=""
              size="sm"
              variant="flat-primary"
            >
              <feather-icon class="mr-50" icon="Edit2Icon"/>
              Modifier
            </b-button>
          </div>

          <!-- Column: Common -->
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">Indiquer 1 à</span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> des {{ props.total }} données</span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BIcon,
  BIconHouseDoorFill,
  BPagination,
} from 'bootstrap-vue';

import {VueGoodTable} from 'vue-good-table';
import {onMounted, ref,computed,reactive} from '@vue/composition-api';
import AgencyAddNew from './AddNewAgencyModal.vue';
import {useAvatarCapabilities} from "@/shared/composables/use-avatar-capabilities";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useUserApi} from "@/views/administrators/composables/use-user-api";
import { useAgencyApi } from "@/modules/agency/composables/use-agency-api";

export default {
  components: {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    VueGoodTable,
    AgencyAddNew,
    BIcon, BIconHouseDoorFill,
  },
  setup() {
    const {logger, httpClient} = useApplicationContext();

    const agencies = ref([]);
    const agenciesMembers=ref([]);
    const searchTerm=ref('');
    const {fetchMembersOfAgency} = useUserApi();

    let isAddNewAgencySidebarActive = false;

    const fetchAgencies = async () => {
        agencies.value = await useAgencyApi().listAgencies();
    };
    const fetchAgenciesMembers = async () => {
      for (const agency of agencies.value) {
        let { data } = await fetchMembersOfAgency(agency.id);
        agenciesMembers.value.push({ ...agency, members: data });
      }
    };
    const filteredAgencies = computed(() => {
      const term = searchTerm.value.toLowerCase();
      return agenciesMembers.value.filter(({ name, email, additional_information: { branch_number }, phone_number, members }) => (
        [name, email, branch_number, phone_number, ...members.map(({ full_name }) => full_name)].some((name) =>
          name.toLowerCase().includes(term))
      ));
    });
    onMounted(async () => {
      await fetchAgencies();
      await fetchAgenciesMembers();
    });
    const {avatarColors,flattenUUIDtoBase} = useAvatarCapabilities()



    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Agence',
          field: 'name',
        },
        {
          label: 'Courriel principal',
          field: 'email',
        },
        {
          label: 'Téléphone',
          field: 'phone_number',
        },
        {
          label: 'Code TDC',
          field: 'additional_information.branch_number',
          sortable: true,
          type: Number
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm,
      agencies,
      agenciesMembers,
      isAddNewAgencySidebarActive,
      fetchAgencies,
      fetchAgenciesMembers,
      filteredAgencies,
      flattenUUIDtoBase,
      avatarColors,

    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
